import React from 'react';
import {
    Box,
    IconButton
} from "@mui/material";
import user1Icon from "../../../assets/icons/users/user1.png"
import EditIconBtn from "../../../Components/Supcomponents/Buttons/EditIconBtn";
import DeleteIconBtn from "../../../Components/Supcomponents/Buttons/DeleteIconBtn";
import clockIcon from "../../../assets/icons/clock.png"

const TaskCardComponent = ({ task }) => {
    return (
        <div  className="task-card bg-white p-3">
            <div className="task-card-content gap-2">
                <Box className="task-card-header flex justify-between items-center">
                    <div className="text-[24px] flex items-center font-medium">
                        <p  color="text.primary" >
                            {task.description}
                        </p>
                    </div>
                    <Box className="task-card-avatar flex items-center">
                        <Box className="task-card-avatar-box flex gap-2 items-center ">
                            <img alt={"img"}
                                 src={task.imgUrl}
                                 className={"w-[25px] h-[25px] rounded-full"}
                            />
                            {/*<Avatar className="ml-3" src={task.imgUrl} alt={task.name}/>*/}
                            <p className="task-card-name ">{task.name}</p>
                        </Box>
                    </Box>
                </Box>
                <Box className="task-card-footer">
                    <Box className="task-card-due-date flex w-2/4 justify-center items-center gap-3 ">
                        <p className={"text-center"}>{task.dueDate}</p>
                        <img alt={"img"}
                             src={clockIcon}
                             className={"w-[18px] h-[18px]"}
                        />
                    </Box>
                    <Box className={"flex items-end"}>
                        <IconButton aria-label="edit" size="small" sx={{marginRight: 1}}>
                            <EditIconBtn className={"text-[13px]"}  />
                        </IconButton>
                        <IconButton aria-label="delete" size="small">
                            <DeleteIconBtn className={"text-[13px]"} />
                        </IconButton>
                    </Box>
                </Box>
            </div>
        </div>
    );
};

export default TaskCardComponent;