// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-card {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 20px;
    width: 300px;
    margin: auto;
    text-align: center;
}

.custom-card p {
    margin: 10px 0;
}

.text-danger {
    color: red;
}

.mx-2 {
    margin: 0 2px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Supcomponents/Forms/FolderUploader.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,sBAAsB;IACtB,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".custom-card {\n    background-color: #f0f0f0;\n    border: 1px solid #ccc;\n    padding: 20px;\n    width: 300px;\n    margin: auto;\n    text-align: center;\n}\n\n.custom-card p {\n    margin: 10px 0;\n}\n\n.text-danger {\n    color: red;\n}\n\n.mx-2 {\n    margin: 0 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
