import React from 'react';
import TableWithBtns from "../../../../Tables/TableWithBtns";
import PointColorComponent from "../../../../Components/PointColor.component";
import { Box, Checkbox } from '@mui/material';
import editIcon from "../../../../assets/icons/actions/edit.png"

function ContentsCategoriesTap(props) {
    return (
        <Box px={10} py={7}>
            <TableWithBtns
                title={"فئات المحتوى"}
                classNameIcons={"p-0"}
                classNameHeader={"items-center"}
                className={"gap-5 w-full"}
                deleteIcon={true}
                departmentIcon={true}
            >
                <thead className="table-font text-primary text-gray-900 uppercase dark:text-gray-400 title-table-font">
                <tr>
                    <th scope="col" className="py-3">
                        <input type={"checkbox"} name={""} onChange={() => {
                        }}/>
                    </th>
                    <th scope="col" className="py-3">
                        اسم الفئة
                    </th>
                    <th scope="col" className="py-3">
                        المشرف
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="py-3">
                        <input type={"checkbox"} name={""} onChange={() => {
                        }}/>
                    </td>
                    <td className="py-3">محتوى ديني</td>
                    <td className="py-3">محمد علي</td>
                    <td className={"py-3 w-[40%]"}>
                        <Box display="flex" gap={1}>
                            {Array.from({ length: 18 }, (v, i) => i + 1).map(() => (
                                <PointColorComponent classNameCyrcle={"bg-[#49869666]"} />
                            ))}
                        </Box>
                    </td>
                    <td className={"py-3 font-bold"}>
                        <img
                            alt={"img"}
                            className={"w-[18px]"}
                            src={editIcon}
                        />
                    </td>
                </tr>
                <tr>
                    <td className="py-3">
                        <input type={"checkbox"} name={""} onChange={() => {
                        }}/>
                    </td>
                    <td className="py-3">محتوى سياسي</td>
                    <td className="py-3">اسامه خالد</td>
                    <td className={"py-3 w-[40%]"}>
                        <Box display="flex" gap={1}>
                            {Array.from({ length: 18 }, (v, i) => i + 1).map(() => (
                                <PointColorComponent classNameCyrcle={"bg-[#49869666]"} />
                            ))}
                        </Box>
                    </td>
                    <td className={"py-3 font-bold"}>
                        <img
                            alt={"img"}
                            className={"w-[18px]"}
                            src={editIcon}
                        />
                    </td>
                </tr>
                <tr>
                    <td className="py-3">
                        <input type={"checkbox"} name={""} onChange={() => {
                        }}/>
                    </td>
                    <td className="py-3">محتوى بيوتي</td>
                    <td className="py-3">عمر سالم</td>
                    <td className={"py-3 w-[40%]"}>
                        <Box display="flex" gap={1}>
                            {Array.from({ length: 18 }, (v, i) => i + 1).map(() => (
                                <PointColorComponent classNameCyrcle={"bg-[#49869666]"} />
                            ))}
                        </Box>
                    </td>
                    <td className={"py-3 font-bold"}>
                        <img
                            alt={"img"}
                            className={"w-[18px]"}
                            src={editIcon}
                        />
                    </td>
                </tr>
                </tbody>
            </TableWithBtns>
        </Box>
    );
}

export default ContentsCategoriesTap;
