import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Avatar,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"; // Three dots icon
import AddIcon from "@mui/icons-material/Add"; // "+" icon
import "./styles.css";
import EditIconBtn from "../../../Components/Supcomponents/Buttons/EditIconBtn";
import DeleteIconBtn from "../../../Components/Supcomponents/Buttons/DeleteIconBtn";
import ListTasksComponent from "./ListTasksComponent";
import NewListTasks from "./NewListTasks";
import user1Icon from "../../../assets/icons/users/user1.png"
import user2Icon from "../../../assets/icons/users/user2.png"
import user3Icon from "../../../assets/icons/users/user3.png"

const tasks = [
  {
    name: "كريم",
    description: "مهمه تحليل بيانات",
    dueDate: "2024-08-01",
    imgUrl:user1Icon,
  },
  {
    name: "علي",
    description: "مهمه تحليل بيانات",
    dueDate: "2024-08-02",
    imgUrl:user2Icon,
  },
  {
    name: "رامي",
    description: "مهمه تحليل بيانات",
    dueDate: "2024-08-03",
    imgUrl:user3Icon,
  },
];


const TaskListComponent = () => {
  return (
      <div className="pt-7  px-10 w-full flex flex-wrap gap-5">
        <ListTasksComponent tasks={tasks} className={"md:w-[32%] sm:w-[48%] w-full"} />
        <ListTasksComponent tasks={tasks} className={"md:w-[32%] sm:w-[48%] w-full"} />
        <NewListTasks className={"md:w-[32%] sm:w-[48%] w-full"} />
      </div>
  );
};

export default TaskListComponent;
