import React, { useState, useRef, useEffect } from 'react';
import { Form, Formik } from "formik";
import TextInput from "../../Components/Supcomponents/inputs/TextInput";
import DefaultSelect from "../../Components/Supcomponents/inputs/DefaultSelect";
import DefaultBtn from "../../Components/Supcomponents/Buttons/DefaultBtn";
import DefaultModal from "../DefaultModal";
import DefaultTextArea from "../../Components/Supcomponents/inputs/DefaultTextArea";
import InputWithIcon from "../../Components/Supcomponents/inputs/InputWithIcon";
import DefaultDateInput from "../../Components/Supcomponents/inputs/DefaultDateInput";
import SelecteAutoComplete from "../../Components/Supcomponents/inputs/SelecteAutoComplete";
import WarningIcon from "../../assets/icons/alerts/alert.png";
import CustomSelect from "../../Components/Supcomponents/inputs/CustomSelect";

function CreateTaskModal({ isModalOpen, onClose }) {
    const [isEmployeeDropdownOpen, setEmployeeDropdownOpen] = useState(false);
    const [isPriorityDropdownOpen, setPriorityDropdownOpen] = useState(false);
    const [isDatelineDropdownOpen, setDatelineDropdownOpen] = useState(false);

    // Refs for dropdowns
    const employeeDropdownRef = useRef(null);
    const priorityDropdownRef = useRef(null);
    const datelineDropdownRef = useRef(null);

    // Handle form submission
    const handleSubmit = (values) => {
        // handle form submission
    };

    const priorityOptions = [
        {_id:"مهمة مستعجلة", name:"مهمة مستعجلة"},
        {_id:"مهمة يومية", name:"مهمة يومية"},
        {_id:"مهمة غير مستعجلة", name:"مهمة غير مستعجلة"},
    ];

    const priorityOptionsColors = [
        {
            name:"danger",
            color:"#e74c3c"
        },
        {
            name:"success",
            color:"#2ecc71"
        },
        {
            name:"warning",
            color:"#f39c12"
        },
    ];

    // Close all dropdowns
    const closeAllDropdowns = () => {
        setEmployeeDropdownOpen(false);
        setPriorityDropdownOpen(false);
        setDatelineDropdownOpen(false);
    };

    // Handle click outside to close dropdowns
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                (employeeDropdownRef.current && !employeeDropdownRef.current.contains(event.target)) &&
                (priorityDropdownRef.current && !priorityDropdownRef.current.contains(event.target)) &&
                (datelineDropdownRef.current && !datelineDropdownRef.current.contains(event.target))
            ) {
                closeAllDropdowns();
            }
        };

        if (isEmployeeDropdownOpen || isPriorityDropdownOpen || isDatelineDropdownOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isEmployeeDropdownOpen, isPriorityDropdownOpen, isDatelineDropdownOpen]);

    return (
        <DefaultModal
            classNameContener={"bg-white bg-opacity-50"}
            classNameModal={"w-full md:w-[30%] font-family-primary shadow shadow-[#A6CDD7CC]"}
            isModalOpen={isModalOpen}
            onClose={onClose}
            isTitle={true}
            title={"انشاء مهمة"}
        >
            <Formik
                initialValues={{ name: "", parent: "" }}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, setFieldValue }) => (
                    <Form className="p-5">
                        <div className={"flex flex-col gap-2"}>
                            <div className={"flex flex-col w-full gap-3"}>
                                <div className={"w-full px-2"}>
                                    <TextInput
                                        component={TextInput}
                                        classNameInput={"rounded-[6px]"}
                                        name="name"
                                        title="العنوان"
                                        error={touched.name && errors.name}
                                        onChange={(e) => setFieldValue("name", e.target.value)}
                                    />
                                </div>
                                <div className={"w-full px-2"}>
                                    <DefaultTextArea
                                        className={"rounded-[6px] px-5"}
                                        multiline
                                        placeholder="الوصف"
                                        rows={4}
                                        title="الوصف"
                                        required
                                        error={
                                            errors.description &&
                                            touched.description &&
                                            errors.description
                                        }
                                    />
                                </div>
                            </div>
                            <div className={"flex w-full justify-center gap-3"}>
                                {/* Employee Button */}
                                <div className="relative" ref={employeeDropdownRef}>
                                    <DefaultBtn
                                        className={"w-24"}
                                        classBtn={"bg-primary-btn text-primary w-full rounded-[6px]"}
                                        title={"موظف"}
                                        onClick={() => {
                                            closeAllDropdowns();
                                            setEmployeeDropdownOpen(!isEmployeeDropdownOpen);
                                        }}
                                        type="button"
                                    />
                                    {isEmployeeDropdownOpen && (
                                        <div
                                            className="absolute flex flex-col z-10 bg-white shadow-lg rounded-md mt-2 w-56 p-2 gap-3 border border[#A6CDD766]">
                                            <DefaultSelect
                                                name="department"
                                                classNameSelect={"select-arrow-left py-1"}
                                                isTitle={true}
                                                title={"الفريق"}
                                                options={[
                                                    {_id: "employee1", name: "موظف 1"},
                                                    {_id: "employee2", name: "موظف 2"},
                                                    {_id: "employee3", name: "موظف 3"},
                                                ]}
                                                onChange={(value) => setFieldValue("department", value)}
                                                className="rounded-[6px]"
                                            />
                                            <DefaultSelect
                                                name="assignTo"
                                                classNameSelect={"select-arrow-left py-1"}
                                                isTitle={true}
                                                title={"الموظف"}
                                                options={[
                                                    {_id: "employee1", name: "موظف 1"},
                                                    {_id: "employee2", name: "موظف 2"},
                                                    {_id: "employee3", name: "موظف 3"},
                                                ]}
                                                onChange={(value) => setFieldValue("assignTo", value)}
                                                className="rounded-[6px]"
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className="relative" ref={datelineDropdownRef}>
                                    <DefaultBtn
                                        classBtn={"bg-primary-btn text-primary w-full rounded-[6px]"}
                                        className={"w-24"}
                                        title={"وقت التسليم"}
                                        onClick={() => {
                                            closeAllDropdowns();
                                            setDatelineDropdownOpen(!isDatelineDropdownOpen);
                                        }}
                                        type="button"
                                    />
                                    {isDatelineDropdownOpen && (
                                        <div
                                            className="absolute flex flex-col z-10 bg-white shadow-lg rounded-md mt-2 w-80 p-3 gap-3 border border[#A6CDD766]">
                                            <div className={"flex justify-between"}>
                                                <InputWithIcon
                                                    className={"w-[49%]"}
                                                    iconClass="far fa-clock text-[17px]"
                                                    classNameLabel={"rounded-[6px] py-1"}
                                                    title="الوقت بالساعات"
                                                    required
                                                    type="number"
                                                    fullWidth
                                                />
                                                <InputWithIcon
                                                    className={"w-[49%]"}
                                                    classNameLabel={"rounded-[6px] py-1"}
                                                    iconClass="far fa-clock text-[17px]"
                                                    title="الوقت بالدقائق"
                                                    required
                                                    type="number"
                                                    fullWidth
                                                />
                                            </div>
                                            <DefaultDateInput
                                                value={""}
                                                classNameLabel={"rounded-[6px] py-1"}
                                                onChange={() => {
                                                }}
                                                title="موعد بدايه المهمه"
                                                required
                                            />
                                            <DefaultDateInput
                                                value={""}
                                                onChange={() => {
                                                }}
                                                classNameLabel={"rounded-[6px] py-1"}
                                                title="اخر موعد تسليم"
                                                required
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className="relative" ref={priorityDropdownRef}>
                                    <DefaultBtn
                                        classBtn={"bg-primary-btn text-primary w-full rounded-[6px]"}
                                        className={"w-24"}
                                        title={"الأهمية"}
                                        onClick={() => {
                                            closeAllDropdowns();
                                            setPriorityDropdownOpen(!isPriorityDropdownOpen);
                                        }}
                                        type="button"
                                    />
                                    {isPriorityDropdownOpen && (
                                        <div
                                            className="absolute flex flex-col z-10 bg-white shadow-lg rounded-md mt-2 w-56 p-2 gap-3 border border[#A6CDD766]">
                                            <DefaultSelect
                                                name="priority"
                                                isTitle={true}
                                                title={"مقدار الاولوية"}
                                                options={priorityOptions}
                                                onChange={(value) => setFieldValue("priority", value)}
                                                className="rounded-[6px]"
                                                classNameSelect={"select-arrow-left py-1"}
                                            />
                                            <CustomSelect
                                                name="priority"
                                                color={true}
                                                options={priorityOptionsColors}
                                                onChange={(value) => setFieldValue("priority", value)}
                                                className="rounded-[6px] py-1"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={"flex gap-1 flex-row-reverse justify-center mt-10 mb-5 "}>
                            <DefaultBtn
                                classBtn={"bg-primary text-white w-20 rounded-[6px]"}
                                title={"حفظ"}
                                type="submit"
                                disabled={isSubmitting}
                            />
                            <DefaultBtn
                                classBtn={"bg-primary-btn text-primary w-20 rounded-[6px]"}
                                title={"الغاء"}
                                onClick={onClose}
                                type="button"
                                disabled={isSubmitting}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </DefaultModal>
    );
}

export default CreateTaskModal;
