import React from 'react';

function DeleteReply(props) {
    return (
        <div className={"py-4"}>

        </div>
    );
}

export default DeleteReply;